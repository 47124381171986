html {
    font-size: 16px;
}

body,
input,
output,
select,
textarea {
    margin: 0;
    color: black;
}

button {
    cursor: pointer;
}

@font-face {
    font-family: Averta;
    src: local(AvertaStdRegular), url(./fonts/AvertaStdRegular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Averta;
    src: local(AvertaStdBold), url(./fonts/AvertaStdBold.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}

body {
    margin: 0;
    font-family: Averta;
}
